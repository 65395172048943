import React from 'react';
import Layout from '../components/Layout/layout';
import SEO from '../components/SEO/seo';
import NotFound from '../components/NotFound';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not Found" />
    <NotFound />
  </Layout>
);

export default NotFoundPage;
